var render = function render(){var _vm=this,_c=_vm._self._c;return _c('layout-page',{staticClass:"module-messaging messaging",attrs:{"back":_vm.$route.name === 'sayl-connect_messaging-messaging' ? { name: 'sayl-connect_dashboard-dashboard' } : { name: 'sayl-connect_messaging-messaging' },"subtitle":_vm.$t('conn3ct-wallet.messages_view_description'),"title":_vm.$t('conn3ct-wallet.messages_view_title')}},[(_vm.blockedMessage)?_c('ui-panel',{staticClass:"messaging__feature-locked",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h4',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t(`conn3ct-wallet.${ _vm.blockedMessage }_title`)))])]},proxy:true}],null,false,267984225)},[_c('div',{staticClass:"body"},[_c('p',[_vm._v(_vm._s(_vm.$t(`conn3ct-wallet.${ _vm.blockedMessage }_description`)))]),_c('actions-button',{staticClass:"messaging__feature-locked-cta",attrs:{"appearance":_vm.$pepper.Appearance.PRIMARY},on:{"click":_vm.openProfile}},[_vm._v(_vm._s(_vm.$t('conn3ct-wallet.open_profile')))])],1)]):_c('div',{staticClass:"messaging__body"},[(_vm.loading)?_c('div',{staticClass:"messaging__loader",class:{
        '-is-hidden': _vm.$route.name === 'sayl-connect_messaging-details'
      }},[_c('ui-loader',{attrs:{"size":_vm.$pepper.Size.L}})],1):_vm._e(),((!_vm.topic || !_vm.topics.length) && !_vm.loading)?_c('ui-panel',{staticClass:"messaging__empty",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h3',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('conn3ct-wallet.messages_view_empty_title')))])]},proxy:true}],null,false,3005421863)},[_c('div',{staticClass:"body"},[_c('p',[_vm._v(_vm._s(_vm.$t('conn3ct-wallet.messages_view_empty_description')))])])]):_c('div',{staticClass:"messaging__topics",class:{
        '-is-hidden': _vm.$route.name === 'sayl-connect_messaging-details'
      }},_vm._l((_vm.topics),function(topic){return _c('div',{key:topic.nft.id,staticClass:"messaging__topic",class:{
          '-is-selected': _vm.$route.params.id === _vm.$basil.get(topic, 'nft.id')
        },on:{"click":function($event){return _vm.setSelectedTopic(topic)}}},[_c('ui-illustration',{staticClass:"messaging__nft-illustration",attrs:{"fit":"contain","src":topic.nft.imageUrl,"type":topic.nft.imageType}}),_c('div',{staticClass:"messaging__topic-middle"},[_c('p',{staticClass:"messaging__topic-name"},[_c('span',[_vm._v(_vm._s(_vm.$basil.get(topic, 'nft.name', '')))]),_c('span',[_vm._v(_vm._s(`#${_vm.$basil.get(topic, 'nft.tokenId')}`))])]),_c('p',{staticClass:"messaging__topic-preview"},[_vm._v(_vm._s(_vm.$basil.get(topic, 'lastMessage.message', '').replace(/<\/?[^>]+(>|$)/g, '')))])]),_c('div',{staticClass:"messaging__topic-end"},[_c('div',{staticClass:"messaging__topic-time"},[_vm._v(_vm._s(_vm.topicTime(topic)))]),(topic.unreadsCount > 0)?_c('div',{staticClass:"messaging__topic-count"},[_vm._v(_vm._s(topic.unreadsCount))]):_vm._e()])],1)}),0),_c('router-view',{key:JSON.stringify(_vm.$messaging.selectedTopic),staticClass:"messaging__topic-details"})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }