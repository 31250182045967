<script>
import { DateTime } from 'luxon'

export default {
  name: 'ViewMessaging',

  data() {
    return {
      loading: false,
      blockedMessage: null
    }
  },

  computed: {
    isEmpty() {
      return this.topics.length === 0
    },

    topics() {
      let topics = this.$messaging.overview
      return topics
    },
  },

  methods: {
    async init() {
      this.loading = true
      this.blockedMessage = null

      try {
        await this.$messaging.getOverview()
      } catch(e) {
        $console.error(e)

        if(this.$basil.get(e, 'response.status') === 423) {
          this.blockedMessage = this.$basil.get(e, 'response.data.message')
        }
      } finally {
        this.loading = false
      }
    },

    openProfile() {
      this.$router.push({ name: 'sayl-connect_user-profile' }).catch(() => {})
    },

    topicTime(topic) {
      const ts = this.$basil.get(topic, 'lastMessage.transactionTimestamp')
      let creation = DateTime.fromSeconds(parseInt(ts))
      let now = DateTime.now()

      if(now.startOf('day').toMillis() === creation.startOf('day').toMillis()) {
        return creation.toLocaleString(DateTime.TIME_24_SIMPLE)
      }

      return creation.toLocaleString({day: 'numeric', month: 'short'})
    },

    setSelectedTopic(topic) {
      window.scrollTo(0, 0)
      this.$messaging.selectedTopic = topic
      this.$router.push({ name: 'sayl-connect_messaging-details', params: { id: this.$basil.get(topic, 'nft.id') } }).catch(() => {})
    }
  },

  mounted() {
    this.init()
  }
}
</script>

<template>
  <layout-page
    :back=" $route.name === 'sayl-connect_messaging-messaging' ? { name: 'sayl-connect_dashboard-dashboard' } : { name: 'sayl-connect_messaging-messaging' }"
    class="module-messaging messaging"
    :subtitle="$t('conn3ct-wallet.messages_view_description')"
    :title="$t('conn3ct-wallet.messages_view_title')"
  >
    <ui-panel
      v-if="blockedMessage"
      class="messaging__feature-locked"
    >
      <template v-slot:header>
        <h4 class="title">{{ $t(`conn3ct-wallet.${ blockedMessage }_title`) }}</h4>
      </template>

      <div class="body">
        <p>{{ $t(`conn3ct-wallet.${ blockedMessage }_description`) }}</p>

        <actions-button
          @click="openProfile"
          :appearance="$pepper.Appearance.PRIMARY"
          class="messaging__feature-locked-cta"
        >{{ $t('conn3ct-wallet.open_profile') }}</actions-button>
      </div>
    </ui-panel>

    <div
      v-else
      class="messaging__body"
    >
      <div
        v-if="loading"
        class="messaging__loader"
        :class="{
          '-is-hidden': $route.name === 'sayl-connect_messaging-details'
        }"
      >
        <ui-loader :size="$pepper.Size.L" />
      </div>

      <ui-panel
        v-if="(!topic || !topics.length) && !loading"
        class="messaging__empty"
      >
        <template v-slot:header>
          <h3 class="title">{{ $t('conn3ct-wallet.messages_view_empty_title') }}</h3>
        </template>

        <div class="body">
          <p>{{ $t('conn3ct-wallet.messages_view_empty_description') }}</p>
        </div>
      </ui-panel>

      <!-- <div
        v-else-if="!topics.length"
        class="messaging__empty"
      >
        <ui-loader :size="$pepper.Size.L" />
      </div> -->

      <div
        v-else
        class="messaging__topics"
        :class="{
          '-is-hidden': $route.name === 'sayl-connect_messaging-details'
        }"
      >
        <div
          v-for="topic in topics"
          @click="setSelectedTopic(topic)"
          class="messaging__topic"
          :class="{
            '-is-selected': $route.params.id === $basil.get(topic, 'nft.id')
          }"
          :key="topic.nft.id"
        >
          <ui-illustration
            class="messaging__nft-illustration"
            fit="contain"
            :src="topic.nft.imageUrl"
            :type="topic.nft.imageType"
          />

          <div class="messaging__topic-middle">
            <p class="messaging__topic-name">
              <span>{{ $basil.get(topic, 'nft.name', '') }}</span>
              <span>{{ `#${$basil.get(topic, 'nft.tokenId')}` }}</span>
            </p>

            <p class="messaging__topic-preview">{{ $basil.get(topic, 'lastMessage.message', '').replace(/<\/?[^>]+(>|$)/g, '') }}</p>
          </div>

          <div class="messaging__topic-end">
            <div class="messaging__topic-time">{{ topicTime(topic) }}</div>

            <div
              v-if="topic.unreadsCount > 0"
              class="messaging__topic-count">{{ topic.unreadsCount }}</div>
          </div>
        </div>
      </div>

      <router-view :key="JSON.stringify($messaging.selectedTopic)" class="messaging__topic-details" />
    </div>
  </layout-page>
</template>
